textarea {
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #fff;
    padding: .5rem .5rem;
    border: 1px solid #ced4da;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    appearance: none;
    border-radius: 2px
}

textarea:enabled:hover {
    border-color: #2196f3
}

textarea:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    ;
    border-color: #2196f3
}

textarea.ng-dirty.ng-invalid {
    border-color: #ff735d
}

textarea.p-inputtext-sm {
    font-size: .875rem;
    padding: .4375rem .4375rem
}

textarea.p-inputtext-lg {
    font-size: 1.25rem;
    padding: .625rem .625rem
}

.p-multiselect-chip .p-multiselect-label{
    white-space: break-spaces;

    .p-multiselect-token{
        margin: 0.2rem;
    }
}