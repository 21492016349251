/* You can add global styles to this file, and also import other style files */
html{
    font-size: 0.8rem;
}

body {
    margin: 0;

    color: var(--text-color);

    .material-symbols-outlined {
        font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 200,
        'opsz' 48
      }      

    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 5px;
        background-color: #F5F5F5;
    }
    
    ::-webkit-scrollbar
    {
        width: 7px;
        height: 7px;
        background-color: #F5F5F5;
    }
    
    ::-webkit-scrollbar-thumb
    {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        background-color: #c5c5c5;
    }

    .text-wrap{
        overflow-wrap: anywhere;
    }

    input::-ms-reveal,
        input::-ms-clear {
            display: none;
        }
}

.logo-text{
    color: var(--logo-font-color);
}

.page{
    height: 100%;
    overflow: auto;
}